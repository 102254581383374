<template>
  <div class="list-balance-desktop" :class="{ 'is-show': isOpen }">
    <div class="header">
      <span class="header__title">
        {{ getContent(layoutData, defaultLocaleLayoutData, 'header.balance.tabs.coins') }}
      </span>
      <form-input-search v-model:value="searchValue" class="game-filter__search" placeholder="Search" />
    </div>
    <div class="items">
      <div v-if="filteredItems.length">
        <div
          v-for="item in filteredItems"
          :key="item?.id"
          class="item"
          :class="{ 'is-active': currentCryptoCurrencyId === item.id }"
          @click="setValue(item.id)"
        >
          <atomic-image v-if="item.icon" class="img" :src="item.icon" :not-lazy="true" :defaultImage="DEFAULT_IMG" />
          <span class="code-title">{{ item.name }}</span>
          <div class="amount">
            <span v-if="item.amount?.currenValue" class="amount-currentValue">{{
              formatAmountBasedOnCurrency(item.currency, item.amount?.currenValue)
            }}</span>
            <div v-if="item.amount?.baseValue" class="amount-baseValue">
              <atomic-icon id="usdt" />
              <span>{{ formatValue(item.amount?.baseValue) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="item-empty">
        {{ getContent(layoutData, defaultLocaleLayoutData, 'header.balance.tabs.no_result') }}
      </div>
    </div>
    <div v-if="balanceCurrencies.length" class="footer">
      <button-base key="balance" type="primary" size="sm" @click="openWallet">
        {{ getContent(layoutData, defaultLocaleLayoutData, 'header.balance.tabs.open_wallet') }}
      </button-base>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import { DEFAULT_IMG } from '@skeleton/consts/staticPath';
  const { iconUrl } = useDynamicIcon('currency');

  const emit = defineEmits(['update:value', 'selected', 'close', 'changeActiveAccount']);

  defineProps({
    isOpen: {
      type: Boolean,
      default: false,
    },
  });

  const globalStore = useGlobalStore();
  const { layoutData, defaultLocaleLayoutData } = storeToRefs(globalStore);
  const { formatBalance, getContent, localizePath } = useProjectMethods();
  const walletStore = useWalletStore();
  const { accounts, activeAccount } = storeToRefs(walletStore);
  const { switchAccount } = useWalletStore();

  const searchValue = ref<string>('');
  const currentCryptoCurrencyId = ref(activeAccount.value?.id);
  const router = useRouter();

  const balanceCurrencies = computed(() => {
    return accounts.value.map(item => {
      const balanceFormat = formatBalance(item.currency, item.balance);
      return {
        ...item,
        name: balanceFormat.currency,
        icon: `${iconUrl.value}/${item.currency}.svg`,
        amount: {
          currenValue: balanceFormat.amount,
          baseValue: item.baseCurrencyBalance.toFixed(2),
        },
      };
    });
  });

  const selectCurrency = async (id: string): Promise<void> => {
    if (activeAccount.value?.id === id) return;

    const findAccount = accounts.value.find(account => account.id === id);

    if (findAccount) {
      await switchAccount(findAccount.id);
      emit('changeActiveAccount', findAccount.currency);
    }

    emit('close');
  };
  const filteredItems = computed(() => {
    const searchQuery = searchValue.value.toLowerCase().trim();

    if (!searchQuery) {
      return balanceCurrencies.value;
    }
    return balanceCurrencies.value.filter(item => {
      return item.name.toLowerCase().includes(searchQuery);
    });
  });

  const setValue = (inputValue: string) => {
    currentCryptoCurrencyId.value = inputValue;
    selectCurrency(inputValue);
  };
  const openWallet = () => {
    router.push(localizePath('/wallet'));
    emit('close');
  };
</script>

<style src="~/assets/styles/components/list/balance-desktop.scss" lang="scss" />
