<template>
  <client-only>
    <div class="input-deposit">
      <div
        v-click-outside="closeDropdown"
        class="select"
        :class="{ 'is-open': isShow }"
        @click="toggleAccountSelector('open')"
      >
        <div class="select__content">
          <atomic-image class="input-deposit__logo" :src="currencyImageSrc" :defaultImage="DEFAULT_IMG" />

          <div class="amount" :class="{ 'amount--demo': isDemo }">
            <span>{{ currentValue }}</span>

            <div class="select__icon">
              <atomic-icon id="arrow_expand-close" class="icon-expand" />
            </div>
          </div>
        </div>

        <list-balance-desktop v-if="!isMobile" :is-open="isShow" @close="isShow = false" @click.stop />

        <client-only v-else>
          <layout-bottom-sheet ref="bottomSheet">
            <template #header>
              <span class="title">
                {{ getContent(layoutData, defaultLocaleLayoutData, 'header.balance.tabs.coins') }}
              </span>
            </template>

            <list-balance @close="toggleAccountSelector('close')" />

            <template #footer>
              <button-base type="gray" size="sm" @click="toggleAccountSelector('close')">
                {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.close') }}
              </button-base>
            </template>
          </layout-bottom-sheet>
        </client-only>
      </div>

      <button-base
        type="green"
        size="xs"
        class="input-deposit__wallet-btn"
        :class="{ 'input-deposit__wallet-btn_short': showSmallBtn }"
        @click="openWallet"
      >
        <img v-lazy-load  v-if="showSmallBtn" data-src="/img/wallet.svg" alt="wallet icon" />

        <span v-else>
          {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.deposit') }}
        </span>
      </button-base>
    </div>
  </client-only>
</template>

<script setup lang="ts">
  import { DEFAULT_IMG } from '@skeleton/consts/staticPath';

  import type { IBottomSheetComponent } from '@skeleton/types';

  const props = withDefaults(
    defineProps<{
      isDemo?: boolean;
    }>(),
    {
      isDemo: false,
    }
  );

  const { isGamePage } = storeToRefs(useLayoutStore());

  const isShow = ref(false);

  const { taptic } = useHaptics();

  const toggleSelect = () => {
    if (!isShow.value) {
      useEvent('analyticsEvent', { event: 'openBalancePopup' });
    }

    isShow.value = !isShow.value;
  };

  const closeDropdown = () => {
    if (isShow.value) isShow.value = false;
  };

  const walletStore = useWalletStore();
  const { getContent } = useProjectMethods();
  const { activeAccount } = storeToRefs(walletStore);
  const globalStore = useGlobalStore();
  const { layoutData, defaultLocaleLayoutData } = storeToRefs(globalStore);
  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);
  const { openWalletModal } = useTransactionStore();
  const { iconUrl } = useDynamicIcon('currency');
  const bottomSheet = ref<Maybe<IBottomSheetComponent>>();

  const showSmallBtn = computed(() => isGamePage.value && isMobile.value);

  const currentValue = computed(() => {
    if (props.isDemo) {
      return getContent(layoutData.value, defaultLocaleLayoutData.value, 'header.balance.demoModePlaceholder');
    }

    return formatAmountBasedOnCurrency(activeAccount.value?.currency, activeAccount.value?.balance);
  });

  const currencyImageSrc = ref<string>('');

  const checkImageExists = (src: string) => {
    return !src?.includes('undefined');
  };

  const toggleAccountSelector = (methodName: 'open' | 'close') => {
    if (!isMobile.value) {
      toggleSelect();
    } else {
      bottomSheet.value?.[methodName]();
    }
    taptic('soft');
  };

  const openWallet = (): void => {
    openWalletModal('deposit');
  };

  watchEffect(async () => {
    const newCurrency = activeAccount.value?.currency;
    if (newCurrency) {
      const imageSrc = `${iconUrl.value}/${newCurrency}.svg`;
      const exists = checkImageExists(imageSrc);
      currencyImageSrc.value = exists ? imageSrc : '/img/currency/placeholder.svg';
    }
  });
</script>

<style src="~/assets/styles/components/form/input/deposit.scss" lang="scss" />
